import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loader from './components/others/Loader';
import { I18nContextProvider } from './context/i18n.context';

import LeaveCallScreen from './components/LeaveCallScreen';
import reportWebVitals from './reportWebVitals.js';
import './tailwind.css';
const PrivateRoomProvider = lazy(() => import('./pages/PrivateCall'));
const GroupCall = lazy(() => import('./GroupCall'));
const DEFAULT_CACHE_TIME = 300000; //
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: DEFAULT_CACHE_TIME,
    },
  },
});
const FallBackSuspense = () => {
  return (
    <div className={`flex items-center justify-center flex-1 w-screen h-screen`}>
      <Loader classNames="w-12 h-12" />
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<FallBackSuspense />}>
      <QueryClientProvider client={queryClient}>
        <I18nContextProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <div />
              </Route>
              <Route path="/private" component={PrivateRoomProvider} />
              <Route path="/group" component={GroupCall} />
              <Route path="/leaved-successfully" component={LeaveCallScreen} />
              <Route path="/*">
                <Redirect to={'/'} />
              </Route>
            </Switch>
          </BrowserRouter>
        </I18nContextProvider>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') reportWebVitals();
// unregister();

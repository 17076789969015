/*
 *
 * Project: winshot-crm
 * File Created: 25 August 2021 10:52
 * Author: Bouhouch Amjed (amjedbouhouch@gmail.com)
 * -----
 * Last Modified: 28 September 2021 14:39
 * -----
 * Copyright 2021
 */

import { createContext, useEffect, useState } from 'react';
import { flushLocaleIntl } from '../constants/intl';

type ILocales = 'fr' | 'en' | 'ar';

interface I18nContextProps {
  locale: ILocales;
}
interface I18nContextProps {
  children: JSX.Element;
}

export const I18nContext = createContext<I18nContextProps>({} as I18nContextProps);

export const I18nContextProvider = ({ children }: I18nContextProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    flushLocaleIntl('fr').then(() => setLoading(false));
  }, []);
  if (loading) {
    return null;
  }

  return children;
};

/*
 *
 * Project: winshot-crm
 * File Created: 24 August 2021 14:05
 * Author: Bouhouch Amjed (amjedbouhouch@gmail.com)
 * -----
 * Last Modified: 28 August 2021 13:55
 * -----
 * Copyright 2021
 */
import React from 'react';

export default function Loader({ classNames }) {
  return <span className={`ease-linear border-2 border-t-2 border-gray-100 rounded-full loader ${classNames}`} />;
}

/*
 *
 * Project: winshot-crm
 * File Created: 18 September 2021 09:57
 * Author: Bouhouch Amjed (amjedbouhouch@gmail.com)
 * -----
 * Last Modified: 24 September 2021 23:30
 * -----
 * Copyright 2021
 */
export function loadTranslation(locale: string): Promise<Record<string, string>> {
  return import(`./${locale}`)
    .then((module) => module.translation)
    .catch((error) => {
      throw new Error('Translation not found');
    });
}

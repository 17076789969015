import { createIntl, createIntlCache } from '@formatjs/intl';
import { loadTranslation } from '../i18n';
//params
const locale = 'fr';
const cache = createIntlCache();

export let intl = createIntl(
  {
    locale: locale || 'fr',
    messages: {},
  },
  cache,
);

export const flushLocaleIntl = async (locale: string) => {
  try {
    const translation = await loadTranslation(locale);

    intl = createIntl({
      locale: locale || 'fr',
      messages: translation,
    });
  } catch (err) {}
};
